.contact-us {
  color: #dd003f;
  background: transparent;
  cursor: pointer;
  border: none;
  font-size: 20px;
}

.contact-us:hover,
.contact-us:focus {
  color: white;
}

.flex-parent-ft {
  padding-bottom: 40px;
}

.container.p.centered {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
