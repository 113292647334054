.middle-content h2 {
  font-family: 'Dosis', sans-serif;
  font-size: 60px;
  color: #ffffff;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 60px;
}

.middle-content h1 {
  font-size: 90px;
}