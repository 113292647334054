.blog-detail-ct ol,
.blog-detail-ct ul,
.blog-detail-ct h2,
.blog-detail-ct h3 {
  color: #abb7c4;
}

.blog-detail-ct p,
.blog-detail-ct ul,
.blog-detail-ct ol {
  font-size: 18px;
  text-align: justify;
}

.blog-detail-ct img {
  display: block;
  padding: 10px 0;
  width: 100%;
}
